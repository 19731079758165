import React, {useContext} from "react";
import {Form} from "./Form";
import {GeneratedLicense} from "./GeneratedLicense";
import {Navigation} from "./Navigation";
import {ResultContext} from "../context/ResultContext";

export function Main() {
    const {visible, setVisibleFunc, license, setLicenseFunc, error, setErrorFunc} = useContext(ResultContext)

    return (
        <>
            <div
                className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">

                <img src="https://play.tailwindcss.com/img/beams.jpg" alt=""
                     className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2" width="1308"/>

                <div
                    className="absolute inset-0 bg-[url(https://play.tailwindcss.com/img/grid.svg)] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]">
                </div>
                <Navigation />
                <div
                    className="relative bg-white px-6 pt-4 pb-4 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-7xl sm:rounded-lg sm:px-10 w-8/12">
                    <Form/>
                    {visible && <GeneratedLicense/>}
                </div>
            </div>
        </>
    )
}