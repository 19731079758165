import React, {useContext} from "react";
import {ResultContext} from "../context/ResultContext";

export function GeneratedLicense() {
    const {visible, setVisibleFunc, license, setLicenseFunc, error, setErrorFunc} = useContext(ResultContext)

    return (
        <div className="mx-auto max-w-6xl py-2">
            {license && <>
                <div>
                    <p className="font-bold">Generated license:</p>
                </div>
                <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                    <p className="textnext">{license}</p>
                </div>
            </>}
            {error && <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p className="font-bold">Error</p>
                <br/>
                <p className="textnext">{error}</p>
            </div>}
        </div>
    )
}