import {createContext, useState} from "react";

interface IResultContext {
    visible: boolean
    setVisibleFunc: (newVal:boolean) => void
    license: string
    setLicenseFunc: (newVal:string) => void
    error: string
    setErrorFunc: (newVal:string) => void
}

export const ResultContext = createContext<IResultContext>(
    {
        visible: false,
        setVisibleFunc: (newVal:boolean) => {},
        license: "",
        setLicenseFunc: (newVal:string) => {},
        error: "",
        setErrorFunc: (newVal:string) => {}
    }
)

export const ResultState = ({children} : {children: React.ReactNode}) => {
    const [visible, setVisible] = useState(false)
    const [license, setLicense] = useState("")
    const [error, setError] = useState("")

    const setVisibleFunc = (newVal:boolean) => setVisible(newVal)
    const setLicenseFunc = (newVal:string) => setLicense(newVal)
    const setErrorFunc = (newVal:string) => setError(newVal)

    return (
        <ResultContext.Provider value={{visible, setVisibleFunc, license, setLicenseFunc, error, setErrorFunc}}>
            { children }
        </ResultContext.Provider>
    )
}