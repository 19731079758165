import React from "react";

export function Navigation() {
    return (
        <div className="absolute bg-gray-50 sm:py-0.5 inset-x-0 top-0">
            <nav
                className="h-[50px] flex justify-between px-5 shadow-l ring-1 text-gray-600 ring-gray-900/5 bg-white items-center sm:mx-auto sm:rounded-lg sm:px-10">
                <span className="font-bold">License Generator</span>
            </nav>
        </div>
    )
}