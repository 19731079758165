import React, {useContext, useState} from "react";
import {ErrorMessage} from "./ErrorMessage";
import {ResultContext} from "../context/ResultContext";
import axios, {AxiosError} from "axios";
import {ILicense} from "../models";
import {data} from "autoprefixer";

export function Form() {
    const {visible, setVisibleFunc, license, setLicenseFunc, error, setErrorFunc} = useContext(ResultContext)

    const [formDisabled, setFormDisabled] = useState(false)
    const [buttonText, setButtonText] = useState("Generate License 🚀")

    const [authValue, setAuthValue] = useState('');
    const [authError, setAuthError] = useState('');

    const [clientnameValue, setClientnameValue] = useState('');
    const [clientnameError, setClientnameError] = useState('');

    const [lictypeValue, setLictypeValue] = useState('online');
    const [lictypeError, setLictypeError] = useState('');

    const [exptimeValue, setExptimeValue] = useState('');
    const [exptimeError, setExptimeError] = useState('');

    const changeAuthHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAuthError('')
        setAuthValue(event.target.value)
        emptyResult()
    }
    const changeClientnameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientnameError('')
        setClientnameValue(event.target.value)
        emptyResult()
    }
    const changeLictypeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLictypeError('')
        setLictypeValue(event.target.value)
        emptyResult()
    }
    const changeExptimeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExptimeError('')
        setExptimeValue(event.target.value)
        emptyResult()
    }

    function delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    const emptyResult = () => {
        setVisibleFunc(false)
        setLicenseFunc("")
        setErrorFunc("")
    }

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault()
        setFormDisabled(true)
        emptyResult()

        setAuthError('')
        setClientnameError('')
        setLictypeError('')
        setExptimeError('')

        let toProceed = true
        if (authValue.trim().length === 0) {
            setAuthError('Please enter valid auth key')
            toProceed = false
        }
        if (clientnameValue.trim().length === 0) {
            // TODO check spaces
            setClientnameError('Please enter non empty client name')
            toProceed = false
        }
        if (lictypeValue)
        if (!(lictypeValue.trim() === 'online' || lictypeValue.trim() === 'offline')) {
            setLictypeError('License type is wrong')
            toProceed = false
        }
        if (exptimeValue.trim().length === 0) {
            // TODO check format
            setExptimeError('Please enter non empty client name')
            toProceed = false
        }

        if (!toProceed) {
            setFormDisabled(false)
            return
        }
        setButtonText("Generating...")

        const address = "https://cloudmonitoringlicense.web.app/create-license-v2-with-parameters"

        try {
            const response = await axios.post<ILicense>(address, {
                client_name: clientnameValue,
                license_type: lictypeValue,
                expiration_date: new Date(exptimeValue).toISOString(),
            }, {
                headers: {'Authorization': 'Bearer ' + authValue}
            })
            if ( response.status == 200 || response.status == 201 ) {
                setLicenseFunc(response.data.license)
            } else {
                setErrorFunc('response status is ' + response.statusText)
            }
        } catch (e: unknown) {
            const error = e as AxiosError
            setErrorFunc(error.message)
        }

        setVisibleFunc(true)
        setFormDisabled(false)
        setButtonText("Generate One More License 🚀🚀🚀")
    }

    return (
        <>
            <div className="mx-auto max-w-xl">
                <form className="divide-y divide-gray-300/50" onSubmit={submitHandler}>
                    <div className="block py-2">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-m font-bold mb-2" htmlFor="auth">
                                Auth token:
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="auth"
                                type="text"
                                placeholder="Auth token here"
                                autoComplete="off"
                                value={authValue}
                                onChange={changeAuthHandler}
                                disabled={formDisabled}
                            />

                            {authError && <ErrorMessage error={authError}/>}

                        </div>
                    </div>
                    <div className="block py-2">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-m font-bold mb-2" htmlFor="clientname">
                                Clients name:
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="clientname"
                                type="text"
                                placeholder="Enter new client's name"
                                autoComplete="off"
                                value={clientnameValue}
                                onChange={changeClientnameHandler}
                                disabled={formDisabled}
                            />

                            {clientnameError && <ErrorMessage error={clientnameError}/>}

                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-m font-bold mb-2" htmlFor="lictype">
                                License check type:
                            </label>
                            <div className="inline-block relative w-full">
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="lictype"
                                    value={lictypeValue}
                                    onChange={changeLictypeHandler}
                                    disabled={formDisabled}
                                >
                                    <option value="online">Online 🌐</option>
                                    <option value="offline">Offline ⭕️</option>
                                </select>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </div>
                            </div>

                            {lictypeError && <ErrorMessage error={lictypeError}/>}

                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-m font-bold mb-2" htmlFor="exptime">
                                Expire at:
                            </label>
                            <input
                                type="datetime-local"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="exptime"
                                value={exptimeValue}
                                onChange={changeExptimeHandler}
                                disabled={formDisabled}
                            />

                            {exptimeError && <ErrorMessage error={exptimeError}/>}

                        </div>

                    </div>
                    <div className="flex py-4 items-center justify-between">
                        <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-green-700"
                            type="submit"
                            disabled={formDisabled}
                        >
                            {buttonText}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}