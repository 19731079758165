import React from 'react';
import {Main} from "./components/Main";

function App() {
    return (
        <Main/>
    );
}

export default App;
